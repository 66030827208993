import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
	private readonly ONBOARDING_KEY = 'hasSeenOnboarding';
  constructor() { }

	// Verifica si es la primera vez que se abre la app
	isFirstTime(): boolean {
		return !localStorage.getItem(this.ONBOARDING_KEY);
	}

	// Marca que ya se mostró el onboarding
	setOnboardingShown(): void {
		localStorage.setItem(this.ONBOARDING_KEY, 'true');
	}
}
