import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {ChatsListPage} from "@pages/support/chats-list/chats-list.page";
import {ChatSupportPage} from "@pages/support/chat-support/chat-support.page";

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'welcome',
		pathMatch: 'full',
	},
	{
		path: 'welcome',
		canActivate: [AuthGuard],
		loadComponent: () => import('./pages/general/welcome/welcome.page').then((m) => m.WelcomePage),
		data: {
			metaDescription:
				'Terapia psicológica en linea, meditaciones guiadas, mindfulness y ejercicios de yoga. ¡Comienza tu viaje hacia una mente y cuerpo saludables hoy mismo!.',
		},
	},
	{
		path: 'home',
		canActivate: [AuthGuard],
		loadComponent: () => import('./pages/general/home/home.page').then((m) => m.HomePage),
	},
	{
		path: 'login-patient',
		canActivate: [AuthGuard],
		loadComponent: () => import('./pages/general/login/patient/login-patient.page').then((m) => m.LoginPatientPage),
		data: {
			metaDescription: 'Haz login como paciente en Mind2 y se parte del cambio. Potencia tu mente.',
		},
	},
	{
		path: 'login-therapist',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/general/login/therapist/login-therapist.page').then((m) => m.LoginTherapistPage),
		data: {
			metaDescription: 'Haz login como terapeuta en Mind2 y se parte del cambio. Potencia tu mente.',
		},
	},
	{
		path: 'sign-up-patient',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/general/signup/patient/sign-up-patient.page').then((m) => m.SignUpPatientPage),
		data: {
			metaDescription: 'Registrate como paciente en Mind2 y se parte del cambio. Potencia tu mente.',
		},
	},
	{
		path: 'sign-up-therapist',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/general/signup/therapist/sign-up-therapist.page').then((m) => m.SignUpTherapistPage),
		data: {
			metaDescription: 'Registrate como terapeuta en Mind2 y se parte del cambio. Potencia tu mente.',
		},
	},
	{
		path: 'recovery-password',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/patient/recovery-password/recovery-password.page').then((m) => m.RecoveryPasswordPage),
		data: {
			metaDescription: 'Recupera la contraseña de tu cuenta de Mind2.',
		},
	},
	{
		path: 'recovery-password-therapist',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/therapist/recovery-password/recovery-password-therapist.page').then(
				(m) => m.RecoveryPasswordTherapistPage,
			),
		data: {
			metaDescription: 'Recupera la contraseña de tu cuenta de Mind2.',
		},
	},
	{
		path: 'terms-patient',
		canActivate: [AuthGuard],
		loadComponent: () => import('./pages/general/terms/patient/terms-patient.page').then((m) => m.TermsPatientPage),
		data: {
			metaDescription: 'Encuentra los términos y condiciones de Mind2.',
		},
	},
	{
		path: 'terms-therapist',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./pages/general/terms/therapist/terms-therapist.page').then((m) => m.TermsTherapistPage),
		data: {
			metaDescription: 'Encuentra los términos y condiciones de Mind2.',
		},
	},
	{
		path: 'patient',
		canActivateChild: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full',
			},
			{
				path: 'home',
				loadComponent: () => import('./pages/patient/home/home-patient.page').then((m) => m.HomePatientPage),
				data: {
					metaDescription:
						'¿Cómo te sientes hoy? Cuentanos y te recomendaremos contenido como meditaciones guiadas, mindfulness y ejercicios de yoga.',
				},
			},
			{
				path: 'contents',
				loadComponent: () => import('./pages/patient/contents/contents.page').then((m) => m.ContentsPage),
				data: {
					metaDescription:
						'Encuentra contenido como meditaciones guiadas, mindfulness y ejercicios de yoga que te ayudarán a tener una mente y cuerpo sanos.',
				},
			},
			{
				path: 'therapeutic-space',
				loadComponent: () =>
					import('./pages/patient/therapeutic-space/therapeutic-space.page').then(
						(m) => m.TherapeuticSpacePage,
					),
				data: {
					metaDescription:
						'En este espacio encuentra las herramientas necesarias para obtener citas con nuestro equipo de terapeutas en tu búsqueda de bienestar.',
				},
			},
			{
				path: 'profile',
				loadComponent: () =>
					import('./pages/patient/profile/patient-profile.page').then((m) => m.PatientProfilePage),
				data: {
					metaDescription: 'Ve y edita el perfil de tu cuenta de Mind2.',
				},
			},
			{
				path: 'plans',
				loadComponent: () => import('./pages/patient/plans/plans.page').then((m) => m.PlansPage),
				data: {
					metaDescription:
						'Obtén un plan de sesiones para empezar a recibir terapia en linea por parte de un miembro de nuestro equipo de terapuetas.',
				},
			},
			{
				path: 'schedule',
				loadComponent: () => import('./pages/patient/schedule/schedule.page').then((m) => m.SchedulePage),
				data: {
					metaDescription:
						'Comienza a utilizar tu plan de sesiones y agenda una cita para empezar a recibir terapia en linea por parte de un miembro de nuestro equipo de terapuetas.',
				},
			},
			{
				path: 'chat',
				loadComponent: () => import('./pages/patient/chat/chat.page').then((m) => m.ChatPage),
				data: {
					metaDescription:
						'Platica y conoce a tu terapeuta asignado dentro de Mind2, para empezar con tu búsqueda de bienestar.',
				},
			},
			{
				path: 'my-account',
				loadComponent: () => import('./pages/patient/my-account/my-account.page').then((m) => m.MyAccountPage),
				data: {
					metaDescription:
						'Ve la información básica de tu cuenta de Mind2 y recibe ayuda de nuestro equipo de soporte.',
				},
			},
			{
				path: 'sentiment-information',
				loadComponent: () =>
					import('./pages/patient/contents/sentiment-information/sentiment-information.page').then(
						(m) => m.SentimentInformationPage,
					),
				data: {
					metaDescription: 'Revisa las categorias de los contenidos que puedes encontrar dentro de Mind2.',
				},
			},
			{
				path: 'specialties',
				loadComponent: () =>
					import('./pages/patient/specialties/specialties.page').then((m) => m.SpecialtiesPage),
				data: {
					metaDescription: 'Revisa las especialidades que puedes encontrar dentro de Mind2.',
				},
			},
			{
				path: 'specialties-detail/:id',
				loadComponent: () =>
					import('./pages/patient/specialties/specialties-detail/specialties-detail.page').then(
						(m) => m.SpecialtiesDetailPage,
					),
				data: {
					metaDescription: 'Revisa el detalle y los contenidos relacionados a esta especialidad.',
				},
			},
			{
				path: 'therapists',
				loadComponent: () => import('./pages/patient/therapists/therapists.page').then((m) => m.TherapistsPage),
				data: {
					metaDescription:
						'Busca la especialidad en la que estás interesado y encuentra dentro de nuestro equipo de terapeutas el más indicado para ti.',
				},
			},
			{
				path: 'therapist-profile/:id',
				loadComponent: () =>
					import('./pages/patient/therapists/therapist-profile/therapist-profile.page').then(
						(m) => m.TherapistProfilePage,
					),
				data: {
					metaDescription:
						'Conoce las especialidades y formación académica y profesional de cada miembro de nuestro equipo de terapeutas.',
				},
			},
			{
				path: 'sentiment-information-detail',
				loadComponent: () =>
					import(
						'./pages/patient/contents/sentiment-information-detail/sentiment-information-detail.page'
					).then((m) => m.SentimentInformationDetailPage),
				data: {
					metaDescription:
						'Revisa el detalle de cada uno de nuestros contenidos de audio, visuales y de texto para ayudarte en tu búsqueda de bienestar.',
				},
			},
			{
				path: 'notice-privacy',
				loadComponent: () =>
					import('./pages/patient/notice-privacy/notice-privacy.page').then((m) => m.NoticePrivacyPage),
				data: {
					metaDescription: 'Encuentra la política de privacidad de Mind2.',
				},
			},
			{
				path: 'purchase-plans',
				loadComponent: () =>
					import('./pages/patient/plans/purchase-plans/purchase-plans.page').then((m) => m.PurchasePlansPage),
				data: {
					metaDescription: 'Termina con tu proceso de compra de un plan de sesiones dentro de Mind2.',
				},
			},
			{
				path: 'video-recommendation',
				loadComponent: () =>
					import('./pages/patient/video-recommendation/video-recommendation.page').then(
						(m) => m.VideoRecommendationPage,
					),
				data: {
					metaDescription:
						'¡Encuentra las recomendaciones de contenido que tenemos dentro de Mind2 para ti hoy!.',
				},
			},
			{
				path: 'chat-bot',
				loadComponent: () => import('./pages/patient/chat/chat-bot/chat-bot.page').then((m) => m.ChatBotPage),
			},
			{
				path: 'video-call/:id',
				loadComponent: () => import('./pages/patient/video-call/video-call.page').then((m) => m.VideoCallPage),
				data: {
					metaDescription:
						'Empieza a recibir terapia psicológica en línea con tu terapeuta asignado en Mind2. ¡Potencia tu mente!.',
				},
			},
			{
				path: 'chat-support',
				loadComponent: () =>
					import('./pages/patient/chat/chat-support/chat-support.page').then((m) => m.ChatSupportPage),
				data: {
					metaDescription:
						'Platica con nuestro equipo de soporte en Mind2 para resolver cualquier duda que tengas.',
				},
			},
			{
				path: 'chats-list',
				loadComponent: () => import('./pages/patient/chats-list/chats-list.page').then((m) => m.ChatsListPage),
			},
			{
				path: 'activity-history',
				loadComponent: () => import('./pages/patient/activity-history/activity-history.page').then( m => m.ActivityHistoryPage)
			}
		],
	},
	{
		path: 'therapist',
		canActivateChild: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full',
			},
			{
				path: 'home',
				loadComponent: () =>
					import('./pages/therapist/home/home-therapist.page').then((m) => m.HomeTherapistPage),
				data: {
					metaDescription:
						'Encuentra información básica de tu cuenta en Mind2 y revisa las citas agendadas para el día de hoy.',
				},
			},
			{
				path: 'hours',
				loadComponent: () =>
					import('./pages/therapist/schedule/hours/therapist-hours.page').then((m) => m.TherapistHoursPage),
				data: {
					metaDescription:
						'Administra tus horarios dentro de Mind2 para que tus pacientes sepan la disponibilidad que tienes para agendar una cita contigo.',
				},
			},
			{
				path: 'chat',
				loadComponent: () =>
					import('./pages/therapist/chat/chat-therapist.page').then((m) => m.ChatTherapistPage),
				data: {
					metaDescription: 'Revisa la lista de tus pacientes dentro de Mind2.',
				},
			},
			{
				path: 'chat-patient',
				loadComponent: () =>
					import('./pages/therapist/chat/chat-patient/chat-patient.page').then((m) => m.ChatPatientPage),

				data: {
					metaDescription: 'Platica y conoce a tus pacientes dentro de Mind2. ¡Sé parte del cambio!.',
				},
			},
			{
				path: 'schedule-therapist',
				loadComponent: () =>
					import('./pages/therapist/schedule/schedule-therapist.page').then((m) => m.ScheduleTherapistPage),
			},
			{
				path: 'my-account-therapist',
				loadComponent: () =>
					import('./pages/therapist/my-account/my-account-therapist.page').then(
						(m) => m.MyAccountTherapistPage,
					),
				data: {
					metaDescription: 'Ve y edita el perfil de tu cuenta de Mind2.',
				},
			},
			{
				path: 'notice-privacy-therapist',
				loadComponent: () =>
					import('./pages/therapist/notice-privacy/notice-privacy-therapist.page').then(
						(m) => m.NoticePrivacyTherapistPage,
					),
				data: {
					metaDescription: 'Encuentra la política de privacidad de Mind2.',
				},
			},
			{
				path: 'video-call/:id',
				loadComponent: () =>
					import('./pages/therapist/video-call/video-call.page').then((m) => m.VideoCallPage),
				data: {
					metaDescription:
						'Empieza a dar terapia psicológica en línea a tus pacientes dentro de Mind2. ¡Sé parte del cambio!.',
				},
			},
			{
				path: 'chat-support',
				loadComponent: () =>
					import('./pages/therapist/chat/chat-support/chat-support.page').then((m) => m.ChatSupportPage),
				data: {
					metaDescription:
						'Platica con nuestro equipo de soporte en Mind2 para resolver cualquier duda que tengas.',
				},
			},
			{
				path: 'contents',
				loadComponent: () => import('./pages/patient/contents/contents.page').then((m) => m.ContentsPage),
				data: {
					metaDescription:
						'Encuentra contenidos que pueden ayudarte como acompañamiento dentro de las terapias psicológicas que proporcionas en Mind2.',
				},
			},
			{
				path: 'sentiment-information',
				loadComponent: () =>
					import('./pages/patient/contents/sentiment-information/sentiment-information.page').then(
						(m) => m.SentimentInformationPage,
					),
				data: {
					metaDescription: 'Revisa las categorias de los contenidos que puedes encontrar dentro de Mind2.',
				},
			},
			{
				path: 'sentiment-information-detail',
				loadComponent: () =>
					import(
						'./pages/patient/contents/sentiment-information-detail/sentiment-information-detail.page'
						).then((m) => m.SentimentInformationDetailPage),
				data: {
					metaDescription:
						'Revisa el detalle de cada uno de nuestros contenidos de audio, visuales y de texto para ayudarte en tu búsqueda de bienestar.',
				},
			},
			{
				path: 'schedule',
				loadComponent: () => import('./pages/patient/schedule/schedule.page').then((m) => m.SchedulePage),
				data: {
					metaDescription:
						'Comienza a utilizar tu plan de sesiones y agenda una cita para empezar a recibir terapia en linea por parte de un miembro de nuestro equipo de terapuetas.',
				},
			},
			{
				path: 'chat-desktop',
				loadComponent: () => import('./pages/therapist/chat-desktop/chat-desktop.page').then( m => m.ChatDesktopPage)
			}
		],
	},
	{
		path: 'support',
		canActivateChild: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: 'chats-list',
				pathMatch: 'full',
			},
			{
				path: 'chats-list',
				loadComponent: () =>
					import('./pages/support/chats-list/chats-list.page').then((m) => m.ChatsListPage),
				data: {
					metaDescription:
						'Encuentra la lista de chats de los pacientes y terapeutas de toda la aplicación.',
				},
			},
			{
				path: 'chat-support',
				loadComponent: () =>
					import('./pages/support/chat-support/chat-support.page').then((m) => m.ChatSupportPage),
				data: {
					metaDescription:
						'Chat con los pacientes o terapeutas.',
				},
			},
		]
	},
	{
		path: 'navigation-menu',
		loadComponent: () =>
			import('./components/navigation-menu/navigation-menu.component').then((m) => m.NavigationMenuComponent),
	},
	{
		path: 'navigation-menu-therapist',
		loadComponent: () =>
			import('./components/navigation-menu-therapist/navigation-menu-therapist.component').then(
				(m) => m.NavigationMenuTherapistComponent,
			),
	},
];
